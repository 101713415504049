// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery")
require('../src/index');

import "bootstrap"
import "../stylesheets/application"

var moment = require('moment')
var jQuery = require('jquery')

import "daterangepicker"
import "chartkick/chart.js"
import "vanilla-nested";

// Include jQuery in global and window scope (So you can access it globally).
// In your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// Include moment in global and window scope (So you can access it globally).
global.moment = moment;
window.moment = moment;

// Import order matters.

import "../custom/myTeam"
import "../custom/stopwatch"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
