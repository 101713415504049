function ShowCoachesDIV() {
  var x = document.getElementById("CoachesDIV");
  var y = document.getElementById("ShowCoachesButton");
  if (x.style.display === "none") {
    x.style.display = "block";
    y.classList.add("small-gradient-button-selected");
  } else {
    x.style.display = "none";
    y.classList.remove("small-gradient-button-selected");
  }
}

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("ShowCoachesButton");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      ShowCoachesDIV()
    });
  }
});

function AddExerciseDIV() {
  var x = document.getElementById("AddExerciseDIV");
  var y = document.getElementById("AddExerciseButton");
  if (x.style.display === "none") {
    x.style.display = "block";
    y.classList.add("selected-link");
  } else {
    x.style.display = "none";
    y.classList.remove("selected-link");
  }
}

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("AddExerciseButton");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      AddExerciseDIV()
    });
  }
});

function myTeam0() {
  var x = document.getElementById("myDIV0");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

function myTeam1() {
  var x = document.getElementById("myDIV1");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

function myTeam2() {
  var x = document.getElementById("myDIV2");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

function myTeam3() {
  var x = document.getElementById("myDIV3");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

function myTeam4() {
  var x = document.getElementById("myDIV4");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

function myTeam5() {
  var x = document.getElementById("myDIV5");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

function myTeam6() {
  var x = document.getElementById("myDIV6");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

function myTeam7() {
  var x = document.getElementById("myDIV7");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

function myTeam8() {
  var x = document.getElementById("myDIV8");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

function myTeam9() {
  var x = document.getElementById("myDIV9");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div0-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      myTeam0()
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div1-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      myTeam1()
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div2-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      myTeam2()
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div3-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
        myTeam3()
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div4-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      myTeam4()
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div5-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      myTeam5()
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div6-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      myTeam6()
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div7-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      myTeam7()
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div8-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      myTeam8()
    });
  }
});

document.addEventListener('turbolinks:load', () => {
  var el = document.getElementById("hide-div9-button");
  if (el){
    const clickButton = el;
    clickButton.addEventListener('click', (event) => {
      myTeam9()
    });
  }
});
